@import '../styles/vars';

.divider {
  position: relative;
  top: -0.06em;
  vertical-align: middle;
  display: table;
  margin: 16px 0;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  background: transparent;
  clear: both;
  width: 100%;
  min-width: 100%;
  height: 1px;
  font-family: $Gotham-Narrow-font-family;
}

.divider:before {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid $mercury;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
}

.divider:after {
  position: relative;
  top: 50%;
  display: table-cell;
  width: 50%;
  border-top: 1px solid $mercury;
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
  content: '';
}

.inner-text {
  display: inline-block;
  padding: 0 24px;
}
