@import '../../styles/vars';

$backgroundColor: #f9fafc;
.wrapper:hover {
  cursor: pointer;
  background: $backgroundColor;
}

.text-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  @include media-breakpoint-up(xl) {
    -webkit-line-clamp: 2;
  }
}

.active {
  background: $backgroundColor;
}

.space-name {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new-tag {
  position: absolute;
  right: 0;
}

.time {
  color: $gray;
}

.avatar-url {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.comment-reaction {
  font-family: $Gotham-Narrow-font-family;
  white-space: nowrap;
}
