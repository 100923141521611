@import '../../styles/vars';

.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

.center-wrapper {
  flex: 1 1 auto;
  @include media-breakpoint-up(sm) {
    flex: 1;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

.img {
  width: 100%;
}

.menu-write-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 20px;
}

.space-cover-image {
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  height: 200px;
}

.space-name {
  font-size: 30px;
  color: $white;
}

.space-avatar {
  background-color: $white;
  padding: 0.1rem;
  border-radius: $border-radius-large;
  width: 54px;
  height: 54px;
  margin-top: -27px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border: 2px solid $white;
  font-weight: $bold-font-weight;
  font-family: $Gotham-Narrow-font-family;
}

.space-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  transition: all 0.3s;
}
