@import '../styles/vars';
.space-avatar {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: $bold-font-weight;
  font-family: $Gotham-Narrow-font-family;
}

.img-opacity {
  filter: alpha(opacity=0.5);
  opacity: 0.5;
}

.tooltip {
  margin-left: 20px !important;
  width: 200px;
}
