@import '../../styles/vars';

.box-shadow {
  background-color: $white;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05), inset 0 -0.5px 0 $mercury;
  height: 75px;
  @include media-breakpoint-up(xs) {
    box-shadow: none;
  }
}

.box-space-name {
  composes: box-shadow;
  line-height: 75px;
  position: relative;
  @include media-breakpoint-down(xs) {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0 -0.5px 0 $mercury;
  }
}

.is-authorized {
  @include media-breakpoint-down(xs) {
    margin-right: 75px;
    padding-right: 0 !important;
  }
}

.box-user-status {
  composes: box-shadow;
}

.menu-icon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.space-name {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  margin-left: 1.5rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 70px);
  @include media-breakpoint-down(sm) {
    margin-left: 0.5rem;
    flex: 1;
    text-align: center;
  }
}

.box-avatar {
  height: 75px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include media-breakpoint-down(xs) {
    position: absolute;
    right: 0;
    width: 75px;
    box-shadow: inset 0 -0.5px 0 $mercury;
  }
}

.username {
  font-weight: $bold-font-weight;
}
.avatar {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 2px solid $shamrock;
}

.avatar-wrapper {
  position: relative;
}

//triangle
.triangle:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  bottom: -11px;
  transform: translateX(-50%);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid $mercury;
  z-index: 101;
}

.triangle:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  left: 50%;
  bottom: -12px;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $white;
  z-index: 101;
}

//container
.wrapper {
  list-style: none;
  padding-left: 0;
  padding-top: 10px;
  position: absolute;
  right: 0;
  width: 200px;
  height: 205px;
  background-color: $white;
  border: 1px solid $mercury;
  border-radius: $border-radius-base;
  z-index: 100;
  margin-top: 10px;
}

.label {
  font-weight: $bold-font-weight;
  height: 40px;
  padding-left: 15px;
  cursor: pointer;
}

.item {
  height: 50px;
  padding-left: 15px;
  cursor: pointer;
}

.item {
  transition: all 0.5s;
  position: relative;
}

.item:hover {
  background-color: #fbfbfb;
  & .active-option {
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: $radical-purple;
    transition: all 0.5s;
  }
}

.active-option {
  opacity: 0;
}

.border-bottom {
  height: 1px;
  margin: 0 15px;
  border-bottom: 1px solid $mercury;
}
