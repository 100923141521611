@import '../../styles/_vars.scss';

.wrapper {
  width: 100%;
}

.form-wrapper {
  width: 330px;
  max-width: 90%;
  margin: 0 auto;
}

.avatar-wrapper {
  background-image: url('../../assets/icon/iOS_app_icon.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  width: 52px;
  height: 52px;
  position: relative;
  margin: 16px auto;
}

.avatar-icon {
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: $border-radius-large;
  padding: 0.1rem;
  width: 34px;
  height: 34px;
  border: 2px solid $white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: $bold-font-weight;

  span {
    font-size: 12px !important;
    width: 12px !important;
  }
}

.input {
  height: 50px;
  box-shadow: $box-shadow;
}

.button {
  max-width: none;
}

.text-prompt {
  font-size: $base-font-size-small;
}

.left-arrow-icon,
.left-arrow-icon-modal {
  cursor: pointer;
  width: 22px;
  height: 22px;
}

.left-arrow-icon-modal {
  position: absolute;
  top: 10px;
  left: 10px;
}
