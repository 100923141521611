@import '../../styles/vars';

.input-wrapper {
  max-width: 620px;
  width: 90%;
  margin: 0 auto;
}

.input {
  padding-left: 50px !important;
}

.prefix {
  margin-left: 10px;
}
