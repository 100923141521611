@import '../styles/vars';

.button {
  display: inline-block;
  border-radius: $border-radius;
  text-decoration: none;
  max-width: 220px;
  text-align: center;
  background-color: $white;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  width: 100%;
  font-size: $base-font-size-small;
  line-height: 1;
  white-space: nowrap;
  -webkit-appearance: none;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: 0.1s;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  padding: 10px 15px;
  font-weight: $bold-font-weight;
  button {
    color: white;
  }

  @include media-breakpoint-up(sm) {
    font-size: $base-font-size;
  }
}

.button-filled {
  composes: button;
  background: $shamrock;
  border: 2px solid $shamrock;
  color: $white;
}

.button-filled:focus,
.button-filled:hover {
  outline: none;
}

.button-outline {
  composes: button;
  border: 2px solid $gray-chateau;
  color: $gray-chateau;
}

.button-outline:focus,
.button-outline:hover {
  outline: none;
}

.button-text {
  border-color: transparent;
  background: transparent;
  padding-left: 0;
  padding-right: 0;
  text-transform: capitalize;
  font-weight: normal;
  color: $shamrock;
  text-decoration: underline;
}

.button-text:focus {
  outline: none;
}

.button-text:hover {
  color: $shamrock;
  cursor: pointer;
  text-decoration: underline;
}

//disabled
.button.is-disabled,
.button.is-disabled:focus,
.button.is-disabled:hover {
  color: $mine-gray;
  cursor: not-allowed;
  background-image: none;
  background-color: $mine-gray;
}

.button-filled.is-disabled,
.button-filled.is-disabled:active,
.button-filled.is-disabled:focus,
.button-filled.is-disabled:hover {
  color: #fff;
  background-color: $mine-gray;
  border-color: $mine-gray;
}

.button-outline.is-disabled,
.button-outline.is-disabled:active,
.button-outline.is-disabled:focus,
.button-outline.is-disabled:hover {
  color: #fff;
  background-color: $mine-gray;
  border-color: $mine-gray;
}

.button-text.is-disabled {
  cursor: not-allowed;
  color: $mine-gray;
}

//loading
.button.is-loading {
  position: relative;
  pointer-events: none;

  div {
    font-size: $base-font-size-small;
    text-transform: capitalize;
  }
}

.button.is-loading:before {
  pointer-events: none;
  content: '';
  position: absolute;
  left: -1px;
  top: -1px;
  right: -1px;
  bottom: -1px;
  border-radius: inherit;
  background-color: hsla(0, 0%, 100%, 0.35);
}

.loading-icon {
  width: 16px;
  height: 16px;
}
