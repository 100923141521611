@import '../styles/vars';

.container {
  height: 0;
  transition: height 0.75s ease-in-out;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}
.container-active {
  composes: container;
  height: 70px;
}

.container-active-alabaster {
  composes: container;
  height: 80px;
  border-top: 1px solid $mercury;
  background: $alabaster;
}
