.Loading {
  text-align: center;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.loading-icon {
  width: 50px;
  height: 50px;
}
