@import '../../styles/vars';

.description-wrapper {
  font-family: $Gotham-Narrow-font-family;
}

.space-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
