@import '../../styles/_vars.scss';

.wrapper {
  width: 450px;
  margin: 0 auto;
  max-width: 90%;
}

.avatar-wrapper {
  width: 52px;
  height: 52px;
  position: relative;
  margin: 16px auto;
  border-radius: $border-radius-large;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $bold-font-weight;
  color: $white;
}

.avatar-icon {
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: $border-radius-large;
  padding: 0.1rem;
  background-color: $white;
  width: 32px;
  height: 32px;
}

.input {
  height: 50px;
  box-shadow: $box-shadow;
}

.button {
  max-width: none;
}

.text-prompt {
  font-size: $base-font-size-small;
  font-family: $Gotham-Narrow-font-family;
}

.description {
  font-family: $Gotham-Narrow-font-family;
}
