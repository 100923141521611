//base color
$mine-shaft: #303030;
$side-bar-black: #141414;
$base-black: #191e27;
$white: #fff;
$shamrock: #2cd4ae;
$radical-purple: #9013fe;
$mercury: #e5e5e5;
$gray-chateau: #9099a7;
$mine-gray: #c2c4c7;
$radical-red: #ff3e57;
$outrageous-orange: #ff5d3f;
$border-radius-base: 4px;
$border-radius-large: 6px;
$section-header-border: #ececec;
$gray: #b2b4b9;
$alabaster: #f9f9f9;

//font
$Gotham-Narrow-font-family: 'Gotham Narrow SSm A', 'Gotham Narrow SSm B',
  HelveticaNeue-Light, 'Helvetica Neue Light', 'Helvetica Neue', Helvetica,
  Arial, 'Lucida Grande', sans-serif;
$apple-font: -apple-system, BlinkMacSystemFont, 'Gotham Narrow SSm A',
  'Gotham Narrow SSm A', sans-serif;
$Gotham-font-family: 'Gotham SSm A', 'Gotham SSm B', HelveticaNeue-Light,
  'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
  sans-serif;

$border-radius: 4px;

// Sizes
///////////////////////////////////////////////////////////////////////////////
$base-font-size-small: 12px;
$base-font-size: 14px;
$base-line-height: $base-font-size * 1.5;
$base-font-weight: 400;
$bold-font-weight: 700;

// Box Shadow
$box-shadow: 0 4px 9px rgba(0, 0, 0, 0.02);
$box-shadow-inset: inset 0px -0.5px 0px $mercury;

//bootstrap
//media
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 872px,
  lg: 1020px,
  xl: 1300px,
);

//color
$gray-100: $alabaster;
$gray-300: $mercury;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
