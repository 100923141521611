@import '../../styles/vars';

.info-wrapper {
  flex: 1 1 152px;
  margin-right: 0.3rem;
  font-size: $base-font-size-small;
  @include media-breakpoint-up(sm) {
    max-width: none;
    text-align: right;
    font-size: 14px;
    margin-right: 25px;
  }
}

.btn-wrapper {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: center;
  justify-content: center;
  @include media-breakpoint-up(sm) {
    max-width: 300px;
  }
}

.text-wrapper {
  white-space: nowrap;
}

.button {
  width: 85px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  font-size: 10px;
  flex: 1;
  margin: 0.3rem 0;
  @include media-breakpoint-up(sm) {
    width: 126px !important;
    font-size: $base-font-size-small;
  }

  @media screen and (max-width: 320px) {
    padding: 8px;
    margin: 0.3rem 0.5rem 0 0.5rem;
  }
}

.button:first-child {
  margin-right: 0.5rem;
}
