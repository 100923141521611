@import '../../styles/vars';

.empty-img {
  width: 200px;
  height: 200px;
}

.empty-text {
  font-size: 16px;
  width: 270px;
  text-align: center;
  @include media-breakpoint-up(sm) {
    line-height: 28px;
    width: 320px;
    font-weight: normal;
  }
}
