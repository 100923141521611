@import '../styles/_vars.scss';

.title {
  text-align: center;
  margin: 10px auto;
  font-size: 22px;
  font-weight: $bold-font-weight;
}

.cancel-button-container {
  min-height: 20px;
}

.cancel-icon {
  stroke: white;
  stroke-width: 3;
  cursor: pointer;
  padding: 5px;
}

.content {
  text-align: center;
}
