@import '../../styles/_vars.scss';

.input {
  box-shadow: $box-shadow;
  @include media-breakpoint-up(sm) {
    height: 50px;
  }
}

.button {
  max-width: none;
  @include media-breakpoint-up(sm) {
    height: 50px;
  }
}

.text-prompt {
  font-size: $base-font-size-small;
  font-family: $Gotham-font-family;
}

.country-code {
  outline: none;
  width: 80px !important;
  margin-right: -10px;
  margin-left: -9px;
}

.country-code-input {
  input {
    border: none;
  }
}
