@import '../../styles/vars';

.wrapper {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.header {
  height: 60px;
  font-weight: $bold-font-weight;
  position: relative;
  padding-left: 60px;
  cursor: pointer;
  background-color: #fbfbfb;
}

.header:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 20px;
  width: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../../assets/icon/posts_active.svg');
}
