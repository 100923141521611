@import '../../styles/vars';
.modal-container {
  height: 100%;
  max-width: 970px !important;
  margin: 0 auto !important;
  width: 80vw !important;
  padding: 0 !important;
  border-radius: 0 !important;
  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}

.select-input-wrapper {
  width: 120px;
}

.button,
.cancel-button {
  width: auto;
}

.cancel-button,
.cancel-button:hover,
.cancel-button:focus {
  color: $mine-shaft;
  text-decoration: none;
  margin-right: 35px;
}

.title-input {
  font-size: 22px;
  font-weight: $bold-font-weight;
  border: none;
}

.tooltip {
  padding: 0;
  color: $mine-shaft !important;
  width: 280px;
}

.post-cell {
  margin-bottom: 0;
  li {
    padding-left: 20px !important;
  }
  li:last-child {
    border: none !important;
  }
}

.icon {
  cursor: pointer;
}

.basic-multi-select {
  position: relative;
  font-size: 14px;
  display: inline-block;
  padding: 10px 15px;
  background-color: $white;
  background-image: none;
  border-radius: $border-radius-base;
  border: 1px solid $mercury;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.basic-multi-select:focus {
  border-color: $shamrock;
}

.button-wrapper {
  @include media-breakpoint-down(xs) {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    flex: 1;
  }
}
.selector {
  @include media-breakpoint-up(sm) {
    max-width: 270px;
  }
}
