@import '../../styles/vars';

.container {
  cursor: pointer;
}

.avatars-container {
  display: flex;
  justify-content: center;
}

.avatar {
  border: 1px solid $white;

  &:not(:first-child) {
    margin-left: -5px;
  }
}
