@import '../../styles/vars';

.container {
  min-height: 100vh;
  @include media-breakpoint-up(sm) {
    height: 100vh;
  }
}

.sideBar-wrapper {
  max-width: 100%;
  width: 100%;
  background-color: $white;

  @include media-breakpoint-up(sm) {
    width: 400px;
    flex: 0 0 400px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 3;
    max-width: 400px;
    transition: all 0.5s;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.2);
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    box-shadow: none;
    display: block;
  }
}

.content-wrapper {
  flex: 1;
}

.content-wrapper-show {
  width: 100%;
  max-width: 100%;
}

.mask {
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 2;
  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
  }

  @include media-breakpoint-up(lg) {
    display: none;
  }
}
