@import '../../styles/vars';

.link-container {
  height: 65px;
  border: 1px solid $mercury;
  border-radius: $border-radius;
  color: $mine-shaft;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
  cursor: pointer;
}

.link-service-wrapper {
  flex: 1;
  padding: 0.5rem 1rem;
  width: 0;
}

.image-wrapper {
  height: 100%;
  width: 120px;
  border-right: 1px solid $mercury;
  border-radius: $border-radius 0 0 $border-radius;
  position: relative;
  overflow: hidden;
}

.link-image {
  position: absolute;
  overflow: hidden;
  width: 120px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.link-description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: $font-size-base;
  font-weight: $bold-font-weight;
}

.service-link {
  color: $gray-chateau;
  font-size: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
