@import 'vars';

body {
  margin: 0;
  padding: 0;
  color: $mine-shaft;
  font-family: $Gotham-font-family;
  font-size: $base-font-size;
  font-style: normal;
  font-weight: $base-font-weight;
  -webkit-font-smoothing: antialiased;
}

h1 {
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

a,
a:not([href]):not([tabindex]) {
  color: $shamrock;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  color: $shamrock;
  cursor: pointer;
  text-decoration: underline;
}

.fade-in-enter {
  opacity: 0.01;
}

.fade-in-enter.fade-in-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-in-leave {
  opacity: 1;
}

.fade-in-leave.fade-in-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fade-in-appear {
  opacity: 0.01;
}

.fade-in-appear.fade-in-appear-active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

//react-tooltip
.__react_component_tooltip {
  text-align: center;
}
.__react_component_tooltip.show {
  opacity: 1;
}

.__react_component_tooltip.type-light.border.place-top:before {
  border-top-color: $mercury;
}

.__react_component_tooltip.type-light.border.place-bottom:before {
  border-bottom-color: $mercury;
}

//emoji
.emoji-mart-emoji {
  outline: none;
}
.emoji-mart-emoji:focus {
  outline: none;
}
.__react_component_tooltip.type-light.border.place-right:before {
  border-right-color: $mercury;
}
.__react_component_tooltip.type-light.border.place-left:before {
  border-left-color: $mercury;
}

::-webkit-scrollbar {
  width: 0;
  height: 0;
}

//loading
.indicator {
  width: 50px;
  height: 50px;
  .rotate {
    transform-origin: 50% 50%;
    animation: rotating 1s linear infinite;
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
