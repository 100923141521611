@import '../../styles/_vars.scss';

.wrapper {
  width: 300px;
  margin: 0 auto;
  max-width: 90%;
}

.avatar-icon {
  position: absolute;
  right: 0;
  transform: translate(50%, -50%);
  border-radius: $border-radius-large;
  padding: 0.1rem;
  background-color: $white;
  width: 32px;
  height: 32px;
}

.input {
  height: 50px;
  box-shadow: $box-shadow;
}

.button {
  max-width: 270px;
  @include media-breakpoint-up(sm) {
    height: 50px;
  }
}

.cancel-button {
  margin-top: 1rem;
}

.text-prompt {
  font-size: $base-font-size-small;
}
