@import '../../styles/vars';

.wrapper {
  background-color: $shamrock;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  font-size: 11px;
  a {
    color: $white !important;
  }
  a:hover {
    color: $white;
  }
}

.download-prompt {
  text-transform: none !important;
}
