@import '../../styles/vars';

.operate-bar {
  flex: 1;
  padding: 0;
  background-color: $white;
  border: 1px solid $mercury;
  margin: 0 0.5rem;
}
.suffix {
  width: 175px;
  @include media-breakpoint-down(xs) {
    width: 134px;
  }
}
.input {
  padding-right: 175px !important;
  padding-left: 28px;
  border: none;
  @include media-breakpoint-down(xs) {
    padding-right: 134px !important;
    padding-left: 10px;
  }
}

.not-one-line {
  .suffix {
    width: auto;
    display: block;
    position: relative;
  }
  .input {
    padding-right: 28px !important;
    @include media-breakpoint-down(xs) {
      padding-left: 10px;
      padding-right: 10px !important;
    }
  }
}

.button {
  width: 85px;
  margin-left: 0.5rem;
}
