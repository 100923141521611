@import '../styles/vars';

.input-wrapper {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 100%;
  border-radius: $border-radius-base;
  background-color: $white;
}

.input__inner {
  -webkit-appearance: none;
  background-color: transparent;
  background-image: none;
  border-radius: $border-radius-base;
  border: 1px solid $mercury;
  box-sizing: border-box;
  display: inline-block;
  font-size: inherit;
  height: 100%;
  outline: none;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  padding: 10px 15px;
}

.input__inner:focus {
  border-color: $shamrock;
}

.input__inner::placeholder {
  color: $gray-chateau;
}

.input--prefix .input__inner {
  padding-left: 40px;
}

.input__prefix {
  left: 0;
}
.input__suffix {
  right: 0;
}

.input__suffix,
.input__prefix {
  position: absolute;
  width: 40px;
  top: 0;
  bottom: 0;
  text-align: center;
  color: $gray-chateau;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
}

.input-group {
  width: 100%;
  line-height: normal;
  display: inline-table;
  border-collapse: separate;
  border-spacing: 0;
}

.input-group > .input__inner {
  vertical-align: middle;
  display: table-cell;
  height: inherit;
}

.input-group--prepend .input__inner {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group__prepend {
  color: $gray-chateau;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid $mercury;
  padding: 0 10px;
  width: 1px;
  white-space: nowrap;
  border-right: 0;
  border-radius: $border-radius-base 0 0 $border-radius-base;
  background-color: $white;
}

.input__inner:focus ~ .input-group__prepend {
  border-color: $shamrock;
}

.invalid {
  color: $radical-red;
  border-bottom-color: $radical-red;
  &::placeholder {
    color: $radical-red;
  }
}

.input--suffix .input__inner {
  padding-right: 40px;
}

.textarea__inner {
  display: block;
  resize: vertical;
  padding: 10px 15px;
  box-sizing: border-box;
  width: 100%;
  line-height: 1.5;
  font-size: inherit;
  background-color: transparent;
  background-image: none;
  border: 1px solid $mercury;
  border-radius: $border-radius;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.textarea__inner::placeholder {
  color: $gray-chateau;
}

.textarea__inner:focus {
  outline: none;
  border-color: $shamrock;
}
