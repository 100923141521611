@import '../styles/vars';

.avatar {
  overflow: hidden;
}

.fauxAvatar {
  composes: avatar;
  margin: 0;
  color: #fff;
  text-align: center;
  font-weight: $bold-font-weight;
  font-family: $Gotham-Narrow-font-family;
}
