.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  cursor: pointer;
}

.image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  cursor: default;
  pointer-events: none;
}
