@import '../../styles/vars';

.wrapper {
  position: relative;
}

.header {
  min-height: 63px;
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-color: $white;
  }
}

.content {
  max-width: 100vw;
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin: 63px 0 71px 0;
  }
}

.footer {
  @include media-breakpoint-up(sm) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.title {
  margin-bottom: 0;
}

.message {
  font-size: $base-font-size-small;
  font-family: $Gotham-Narrow-font-family;
  .date-time {
    color: $mine-gray;
    margin: 0 0 1rem;
    font-style: italic;
  }
}

.post-name {
  font-size: 12px;
}

.inner-text {
  font-size: 12px;
  color: $gray-chateau;
}

.avatar-url {
  width: 30px;
  height: 30px;
}

.load-older {
  text-align: center;
  padding-top: 10px;
}
