@import '../styles/vars';

.image-wrapper {
  padding: 0 10px;
  position: relative;
}

.slider-wrapper {
  width: 100%;
  max-width: 85vw;
  padding-bottom: 10px;

  :global {
    .slick-prev {
      left: -10px !important;
    }

    .slick-next {
      right: -10px !important;
    }

    .slick-next:before,
    .slick-prev:before {
      color: $mine-shaft;
    }

    @include media-breakpoint-down(sm) {
      .slick-prev,
      .slick-next {
        display: none !important;
      }
    }
  }
}

.medium {
  width: 100%;
  border-radius: $border-radius;
  cursor: pointer;
  height: 180px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.trash-icon {
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
}

.image-wrapper:hover > .trash-icon {
  visibility: visible;
  transition: all 0.3s;
}

.slick-indicators {
  position: absolute;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 2;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);

  li {
    display: inline-block;
    padding: 12px 4px;
    background-color: transparent;
    cursor: pointer;
  }

  :global {
    .slick-active {
      button {
        background-color: $mine-shaft;
      }
    }
  }

  button {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: $mercury;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: 0.3s;
    font-size: 0;
  }
}

.single-image {
  max-height: 400px;
}
