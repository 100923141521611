@import '../../styles/vars';

.operation-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
}

.content-input {
  height: 100%;
  border: none;
}

.icon {
  cursor: pointer;
}

.bg-transparent {
  background-color: transparent;
  font-family: $Gotham-Narrow-font-family;
}

.title-input {
  font-size: 16px;
  font-weight: $bold-font-weight;
  border: none;
}

.content-input {
  height: 100%;
  border: none;
}

.text-area-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: $Gotham-Narrow-font-family;
}

//link
.link-input {
  border: none;
  border-top: 1px solid $border-color;
  border-radius: 0;
}

.collapse-icon {
  cursor: pointer;
  height: 26px;
  width: 26px;
}

.tooltip {
  width: 220px;
}

.button,
.button:hover {
  color: $white;
  text-decoration: none;
  text-transform: none;
}

.is-collapse {
  justify-content: space-between;
  padding: 1.5rem 1.5rem 0;
  @include media-breakpoint-down(sm) {
    position: relative;
  }
  & .text-area-wrapper {
    @include media-breakpoint-down(sm) {
      position: absolute;
      bottom: 0;
      top: 70px;
      left: 0;
      right: 0;
    }
  }
}
