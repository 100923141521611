@import '../../styles/vars';

.selector {
  max-width: 250px;
}

.base-select {
  background-color: $white;
  border-radius: $border-radius-base;
  height: 22px;
  line-height: 22px;
  input {
    border: none;
  }
}
