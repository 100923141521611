@import '../styles/vars';

.wrapper {
  display: flex;
  padding: 0.5rem 0;
  flex-wrap: wrap;
  font-family: $Gotham-Narrow-font-family;
}

.inactive,
.emoji-wrapper {
  border-radius: 4px;
  box-shadow: $box-shadow;
  padding: 5px 16px;
  margin-right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $mercury;
  background-color: $alabaster;
  margin-bottom: 10px;
  width: 56px;
}

.emoji-wrapper {
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background-color: $white !important;
  }
  & button {
    padding-right: 3px;
  }
}

.empty-emoji-picker {
  width: 338px;
  height: 351px;
}

.tooltip {
  padding: 0;
  color: $mine-shaft !important;

  section {
    border: none;
  }

  :global {
    //emoji picker
    .emoji-mart-category-label {
      text-align: left;
      color: $gray-chateau;
      font-family: $Gotham-font-family;
      font-size: $base-font-size-small;
      span {
        padding: 10px 6px !important;
      }
    }
    .emoji-mart-search-icon {
      top: 50% !important;
      transform: translateY(-50%) !important;
      padding: 0 !important;
    }

    .emoji-mart-search {
      font-weight: $bold-font-weight !important;
      font-family: $Gotham-font-family;
    }
  }
}

.added_reaction {
  border-color: $shamrock !important;
}

.modal-container {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: none;
}

.emoji-picker-modal {
  text-align: left;
  section {
    @include media-breakpoint-down(sm) {
      width: 100% !important;
      border: none;
    }
  }
}
