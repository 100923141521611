@import '../../styles/vars';

.box {
  background-color: $white;
  border: 1px solid $mercury;
  border-radius: $border-radius;
  padding: 1rem 1rem 0;
}

.wrapper {
  max-width: 620px;
  width: 90%;
  margin: 0 auto;
}

.tooltip {
  padding: 0;
  color: $mine-shaft !important;
  width: 300px;

  ul {
    max-height: 320px;
    margin: 0;
    overflow-y: auto;
  }

  li:last-child {
    border: none !important;
  }
}

.select-input {
  width: 120px;
}

.icon {
  width: 24px;
  height: 23px;
  cursor: pointer;
  margin-right: 1rem;
}

.basic-multi-select {
  position: relative;
  font-size: 14px;
  display: inline-block;
  padding: 10px 15px;
  background-color: $white;
  background-image: none;
  border-radius: $border-radius-base;
  border: 1px solid $mercury;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.basic-multi-select:focus {
  border-color: $shamrock;
}

.button {
  width: auto;
}

.selector-box {
  @include media-breakpoint-down(xs) {
    width: 100% !important;
    max-width: none;
  }
}

.icon-box {
  @include media-breakpoint-down(xs) {
    margin-left: 0 !important;
  }
}
