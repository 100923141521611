@import '../styles/vars';

.wrapper {
  list-style: none;
  padding-left: 0;
}

.item {
  height: 50px;
  padding-left: 60px;
  cursor: pointer;
  background-color: #fbfbfb;
}
.item:hover {
  background-color: $section-header-border;
}

.active {
  font-weight: $bold-font-weight;
  background: $section-header-border;
  box-shadow: $box-shadow-inset;
  transition: all 0.5s;
  position: relative;

  .active-topic {
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
  }
}

.active-topic {
  opacity: 0;
}
