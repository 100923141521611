@import '../../styles/vars';

.avatar {
  display: inline-block;
  vertical-align: top;
}

.wrapper {
  margin-bottom: 1.5rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.date-time {
  font-size: 12px;
  color: $mine-gray;
  font-family: $Gotham-Narrow-font-family;
}

.container {
  display: inline-block;
  width: calc(100% - 40px);
}
