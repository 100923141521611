@import '../../styles/vars';

.wrapper {
  position: absolute;
  left: 0;
  right: 0;
  @include media-breakpoint-up(sm) {
    top: 0;
    bottom: 0;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
