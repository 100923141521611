@import '../../styles/vars';

.wrapper {
  width: 58px;
  text-align: center;
  background-color: $side-bar-black;
  color: $white;
  padding: 12px;
  @include media-breakpoint-up(sm) {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
}

.avatar-wrapper {
  @include media-breakpoint-up(sm) {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

.tooltip {
  margin-left: 20px !important;
  width: 200px;
}
