@import '../styles/vars';

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  font-weight: 500;
  font-size: $base-font-size-small;
  text-align: center;
  color: $white;
  transition: all 0.5s;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link {
  text-decoration: none !important;
  color: #fff !important;
}

.button-container {
  display: inline-flex !important;
  align-items: center;
  height: 30pt;
  border-radius: 20pt;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  background: $radical-red;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FF3E57',endColorstr='#FF653E',GradientType=1);
  background: linear-gradient(
    319deg,
    $radical-red,
    $outrageous-orange,
    #fe12d9
  );
  background-size: 400% 400%;
  animation: AnimationButton 3s ease infinite;

  @-webkit-keyframes AnimationButton {
    0% {
      background-position: 49% 0%;
    }
    50% {
      background-position: 52% 100%;
    }
    100% {
      background-position: 49% 0%;
    }
  }
  @-moz-keyframes AnimationButton {
    0% {
      background-position: 49% 0%;
    }
    50% {
      background-position: 52% 100%;
    }
    100% {
      background-position: 49% 0%;
    }
  }
  @keyframes AnimationButton {
    0% {
      background-position: 49% 0%;
    }
    50% {
      background-position: 52% 100%;
    }
    100% {
      background-position: 49% 0%;
    }
  }
}

.close-button {
  cursor: pointer;
  vertical-align: middle;
  img {
    width: 11px;
    height: 11px;
  }
}

.fb-cta {
  border-right: 1px solid $white;
}

.is-visible {
  opacity: 0;
  @include media-breakpoint-down(sm) {
    opacity: 1;
  }
}

.is-hide {
  opacity: 0;
}
