@import '../../styles/vars';

.post-title {
  font-size: 18px;
  font-weight: $bold-font-weight;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80vw;
}

.community-name {
  color: $gray-chateau;
}
