@import '../styles/vars';

.select-wrapper {
  position: relative;
  width: 100%;
  flex: auto;
  &:hover {
    cursor: pointer;
  }
}

.select-input {
  width: 100%;
  font-size: 12px;
}
.select-input .select__caret {
  color: #c0c4cc;
  font-size: 14px;
  transition: transform 0.3s;
  transform: rotate(180deg);
  cursor: pointer;
}

.select-input .select__caret.is-reverse {
  transform: rotate(0deg);
}

.input {
  padding-right: 27px !important;
  cursor: pointer;
}
.input:focus {
  cursor: pointer;
}

.select-dropdown,
.modal-select-dropdown {
  width: auto;
  min-width: 100%;
  position: absolute;
  left: 0;
  margin-top: 10px;
  background-color: $white;
  border: 1px solid $mercury;
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: $border-radius-base;
}

.modal-select-dropdown {
  left: 0;
  bottom: 0;
  right: 0;
  height: 150px;
  overflow-y: auto;
  overflow-x: hidden;
}
.button-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $mercury !important;
  padding: 10px;
}

.button {
  text-decoration: none;
}
.button:first-child {
  color: $mine-shaft;
}

.select-dropdown-top {
  bottom: 50px;
  top: auto;
}

.select-option {
  padding: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;

  &:last-child {
    border: none !important;
  }

  &:hover {
    background: $section-header-border;
    transition: all 0.5s;
    position: relative;
  }

  &:hover .active-option {
    opacity: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 3px;
    background-color: $radical-purple;
  }
}

.active-option {
  opacity: 0;
}

.active {
  font-weight: $bold-font-weight;
}

//triangle
.triangle:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 3px;
  bottom: -11px;
  transform: translateX(-50%);
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid $mercury;
  z-index: 101;
}

.triangle:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 5px;
  bottom: -11px;
  transform: translateX(-50%);
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid $white;
  z-index: 101;
}

.triangle-top:before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 12px;
  top: -14px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid $mercury;
  z-index: 101;
}

.triangle-top:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  right: 12px;
  top: -15px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid $white;
  z-index: 101;
}

.suffix {
  width: 27px;
}
