@import '../../styles/vars';

.ErrorPage {
  text-align: center;

  .container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    .error-code {
      font-size: 54px;
      font-weight: $bold-font-weight;
    }
  }
}
