@import '../../styles/_vars.scss';

.input {
  box-shadow: $box-shadow;
  @include media-breakpoint-up(sm) {
    height: 50px;
  }
}

.button {
  max-width: none;
  @include media-breakpoint-up(sm) {
    height: 50px;
  }
}
