@import '../../styles/vars';

.member-wrapper {
  margin-top: 0.5rem;
}
.members-modal {
  padding: 0 !important;
  border-radius: 0 !important;
}

.member-ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.cancel-wrapper {
  position: absolute;
  right: 0;
  top: 10px;
}

.role-label {
  padding: 20px 22px 5px 22px;
  font-size: 18px;
  font-weight: $bold-font-weight;
  color: #000;
  text-align: left;
  border-bottom: 1px solid $mercury;
  text-transform: capitalize;
}

.role-item {
  height: 78px;
  display: flex;
  padding: 10px 22px;
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $mercury;
}

.avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.has-border {
  border: 1.5px solid $shamrock;
}

.time {
  font-size: $base-font-size-small;
  color: $gray-chateau;
}

.modal-cancel {
  padding: 5px 10px;
}

.text {
  font-family: $Gotham-Narrow-font-family;
}
